import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { DemoRegister } from './components/DemoRegister';
import { Activation } from './components/Activation';
import { Success } from './components/Success';
import { Info } from './components/Info';
import { Feedback } from './components/Feedback'
import { AlreadyRegistered } from './components/AlreadyRegistered'
import { EmptyRedirect } from './components/EmptyRedirect'
import { LicenseListView } from './components/LicenseListView'
import { GetLicense } from './components/GetLicense'

interface IState {
}

interface IProps {

}

export default class App extends React.Component<IProps, IState> {

  constructor(props: any){
    super(props);
  }

  render(){
    return (
        <Switch>
            {/* example: 'www.example.com/demo/AIA.Editor/123456789/windows/MaxPC' */}
            <Route path='/demo/:module/:machineId/:os/:machineName' component={DemoRegister} />
            {/* example: 'www.example.com/activation/123456789/windows/MaxPC' */}
            <Route path='/activation/:machineId/:os/:machineName' component={Activation} />
            {/* example: 'www.example.com/success/demo/2021.10.10' */}
            <Route path='/success/:licenseType/:validUntil' component={Success} />
            {/* example: 'www.example.com/info/123456789' */}
            <Route path='/info/:machineId' component={Info} />
            {/* example: 'www.example.com/feedback' */}
            <Route path='/feedback' component={Feedback}/>
            {/* example: 'www.example.com/alreadyregistered' */}
            <Route path='/alreadyregistered' component={AlreadyRegistered}/>
            {/* example: 'www.example.com/emptyRedirect' */}
            <Route path='/emptyRedirect' component={EmptyRedirect}/>
            {/* example: 'www.example.com/licenselistview/activationcode'*/}
            <Route path='/licenselistview/:activationCode' component={LicenseListView}/>
            {/* example: 'www.example.com/getLicense'*/}
            <Route path='/getlicense' component={GetLicense}/>
        </Switch>
    )
  }
}