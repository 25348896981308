import { Button, Card, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match, useParams } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { EmptyRedirect } from './EmptyRedirect';
import { LicenseDto } from '../Client';
const style = require('./Success.css');


interface IParams {
    licenseType: string;
    validUntil: string;
}

interface IState {
}

interface IProps {
  required: boolean;
  match?: match<IParams>;
}

export class Success extends React.Component<IProps, IState> {
  constructor(props: any){
    super(props);
  }
  render(){
      const params = this.props.match?.params;
    return (
      <React.Fragment>
          <Container id="backgroundEkko" maxWidth="xl" className={'cardStyle'}>
              <div>
                <Typography variant='h4'>Congratulations!</Typography>
                <Typography variant='h5'>You activated your {params?.licenseType} License!</Typography>
              </div>
              <div className={'spaceBorder'}>
                <Typography variant='body1'>Your License is valid until:</Typography>
                <Typography variant='body1'>- <b>{this.props.match?.params.validUntil}</b> -</Typography>
              </div>
              <div className={'spaceBorder'}>
                <Typography variant='body1'>Please check your email inbox to be informed about the status of your license.</Typography>
                <Typography variant='body1'>After testing you can contact us on www.ekkodale.com/tools to get further information on the tools.</Typography>
                <Typography variant='body1'>Or talk to us under <b>+49 611 34198071</b></Typography>
              </div>
              <div className={'spaceBorder'}>
                <Typography variant='body1'>Please note, that the results of a Demo-Licenses may only be used commercially after purchasing a full license. The demo is for testing the functionalities only.</Typography>
              </div>
              <div className={'spaceBorder'}>
                  <Link to="/EmptyRedirect" className="begin-to-BIM">Begin To BIM</Link>
              </div>
          </Container>
        </React.Fragment>
    )
  }
}