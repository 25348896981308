import { Box, Button, Card, CardContent, CircularProgress, Grid, InputLabel, MenuItem, Step, StepLabel, Stepper, Typography} from '@material-ui/core';
import { Field, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxWithLabel, Select, TextField as FormikTextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { Autocomplete } from 'formik-material-ui-lab';
import {TextField, Snackbar} from '@material-ui/core';
import { AutoCompanyResult } from '.././AutoCompanyResult';
import CssBaseline from '@material-ui/core/CssBaseline';
import { match } from 'react-router-dom';
import { Client, CompanyDto, EmployeeDto, LicenseDto, MachineDto, ModuleDto, RegisterDto  } from '../Client';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { Severity } from '../Severity';
import Config from "../Config";

const style = require('./DemoRegister.css');

var client : Client = new Client(Config.BaseUrl);

const sleep = (time: any) => new Promise((acc) => setTimeout(acc, time));

const lang : string = navigator.language;
const langOptions : Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

interface IParams {
  module: string;
  machineId: string;
  os: string;
  machineName: string;
}

interface IState {
  companies: Array<string>;
  isLoading: false;
  snackBarStatus: boolean;
  snackBarSeverity: "error" | "success" | "info" | "warning" | undefined;
  snackBarMessage: string;
}

interface IProps {
  required: boolean;
  match?: match<IParams>;
  history: any;
}

export class DemoRegister extends React.Component<IProps, IState> {

  constructor(props: any){
    super(props);

    let params = this.props.match?.params;

    if(params !== undefined){
      client.getLicenses(params.machineId).then(licenses => {
        licenses.forEach(x => {
          // remove dots and write string to lowercase for comparsion
          let moduleName = x.connectedModule?.split('.').join("").toLowerCase();
          let paramsModuleName = params?.module.split('.').join("").toLowerCase();
          if(moduleName == paramsModuleName){
            this.props.history.push('/alreadyregistered/');
          }
        })
      });
    }

    this.state = {
      companies: new Array(),
      isLoading: false,
      snackBarStatus: false,
      snackBarSeverity: 'error',
      snackBarMessage: '',
    }
  }

  setSnackbarStatus(status?: boolean){
    this.setState({
      snackBarStatus: status !== undefined ? status : !this.state.snackBarStatus
    })
  }

  showSnackbar(severity: any, message: string){
    let _severity : "error" | "success" | "info" | "warning" | undefined = severity.toString();
    this.setState({
      snackBarSeverity: _severity,
      snackBarMessage: message
    });
    this.setSnackbarStatus(true);
  }

  UpdateCompanies(inputValue: string) {
    if(inputValue !== undefined && inputValue !== ""){
      fetch("https://autocomplete.clearbit.com/v1/companies/suggest?query=:" + inputValue).then
      (res => res.json()
      .then((result : AutoCompanyResult[]) => {
        this.state.companies.splice(0, this.state.companies.length)
        result.forEach((company: AutoCompanyResult) => {
          this.state.companies.push(company.name);
        });
      }));
    }
  }

  async registerDemo(values: FormikValues){
    let params = this.props.match?.params;
    let company: CompanyDto = new CompanyDto({
      address: values.address,
      branche: values.branche,
      city: values.city,
      country: values.country,
      name: values.companyName,
      postCode: values.postCode,
      taxId: values.taxID,
      website: values.website
    });
    let employee : EmployeeDto = new EmployeeDto({
      email: values.email,
      familyName: values.familyName,
      firstName: values.firstName,
      salutation: values.salutation,
      language: values.language,
      title: values.title,
      phoneNumber: values.phoneNumber,
      position: values.position
    });
    let module: ModuleDto = new ModuleDto({
      name: params?.module
    });
    let machine: MachineDto = new MachineDto({
      machineIdentification: params?.machineId,
      operatingSystem: params?.os,
      machineName: params?.machineName
    });
    
    let registerDto: RegisterDto = new RegisterDto({
      companyDto: company,
      employeeDto: employee,
      moduleDto: module,
      machineDto: machine
    });

    var result: LicenseDto;
    try {
      result = await client.registerDemo(registerDto);
      if(result !== null && result !== undefined && result.statusCode == 200){
        this.props.history.push('/success/' + result.licenseType + '/' + result.dueDateTotal?.toLocaleDateString(lang, langOptions));
      }
      else{
        throw new Error("Invalid operation");
      }
    } 
    catch (error: any) {
      let errorMessage = error.message;
      if(error == "TypeError: Failed to fetch"){
        errorMessage = "Could not connect to ekkodale servers. Please check your internet connection or try again later.";
      }
      this.showSnackbar(Severity.Error, errorMessage);
    }
  }

  

  render(){
    return (
      <React.Fragment>
        <Card>
          <CardContent id="backgroundEkko" className={'cardStyle'}>
            <FormikStepper 

            initialValues={{
              companyName: '',
              website: '',
              country: '',
              city: '',
              address: '',
              postCode:'',
              branche:'',
              taxID:'',
              firstName:'',
              familyName:'',
              salutation:0,
              language:0,
              title:'',
              position:'',
              email:'',
              phoneNumber:'',
              licenseAgreement: false,
              AGB: false
            }} 
            onSubmit={async(values) => {

              await this.registerDemo(values);
            }}>
              
                <FormikStep label="Company">
                  <p>This form will guide you through the registration process to the demo version. After you have filled in all the required information, you will be able to start working with the tool right away!</p>
                  <Box paddingBottom={2}>
                    <Field 
                      name="companyName"
                      fullWidth 
                      component={Autocomplete}  
                      disableClearable
                      selectOnFocus
                      handleHomeEndKeys
                      onInputChange={(event: any, value: string) => {
                        this.UpdateCompanies(value);
                      }}
                      filterOptions={(params:any, option:any) => {
                        let inputValue : string = option.inputValue;
                        let options = [... this.state.companies];
                        if(inputValue !== "" && !options.includes(inputValue)){
                          options.push(inputValue);
                        }
    
                        return options;
                      }}
                      options={this.state.companies}
                      getOptionLabel={(options : string[]) => options} 
                      getOptionSelected={(option : any, value: any) => option.id === value.id}
                      renderOption={(option: string) => {
                        return this.state.companies.includes(option) ? option  + ", (verified) ☑️" : option;
                      }}
                      renderInput={(params: any) => (
                        <Field required component={TextField} {...params} label="Company Name" InputProps={{ ...params.InputProps, type: 'search'}} />
                    )} />
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "website" component={FormikTextField} label="Website"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "country" component={FormikTextField} label="Country"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "city" component={FormikTextField} label="City"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "address" component={FormikTextField} label="Address"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "postCode" type="number" component={FormikTextField} label="PostCode"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "branche" component={FormikTextField} label="Branch"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "taxID" component={FormikTextField} label="Tax ID"/>
                      <Typography className={'taxHint'}>* for EU members: VAT number</Typography>
                    <Typography className={'taxHint'}>* for non-EU members: Tax number or register entry</Typography>
                  </Box>
                  
                </FormikStep>
                <FormikStep label="Employee">
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "firstName" component={FormikTextField} label="First Name"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "familyName" component={FormikTextField} label="Family Name"/>
                  </Box>
                  <Box paddingBottom={2} paddingTop={2} textAlign="left">
                    <InputLabel>Salutation *</InputLabel>
                    <Field required fullWidth name= "salutation" component={Select} type="text">
                      <MenuItem value={0}>Diverse</MenuItem>
                      <MenuItem value={1}>Mr.</MenuItem>
                      <MenuItem value={2}>Ms.</MenuItem>
                    </Field>
                  </Box>
                  <Box paddingBottom={2} paddingTop={2} textAlign="left">
                    <InputLabel>Language *</InputLabel>
                    <Field required fullWidth name= "language" component={Select} type="text">
                      <MenuItem value={0}>German</MenuItem>
                      <MenuItem value={1}>English</MenuItem>
                    </Field>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field fullWidth name= "title" component={FormikTextField} label="Title"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "position" component={FormikTextField} label="Position"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required  fullWidth name="email" type="email" component={FormikTextField} label="E-Mail"/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field required fullWidth name= "phoneNumber" component={FormikTextField} label="Phonenumber" validate=""/>
                  </Box>
                </FormikStep>
                <FormikStep label="All Done">
                  <h1>All Done</h1>
                  <p>The only Thing left to do is to check your input data and submit it!</p>
                  <p>Also make sure that you agree to our AGB and Licenseagreement!</p>
                  <Box paddingBottom={2}>
                    <p><a href="https://www.ekkodale.com/AGBs" target="_blank">ekkodale GmbH AGB and ekkodale GmbH License Agreement</a></p>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field name= "AGB" required type="checkbox" component={CheckboxWithLabel} Label={{ label: 'I accept ekkodales AGB' }}/>
                  </Box>
                  <Box paddingBottom={2}>
                    <Field name= "licenseAgreement" required type="checkbox" component={CheckboxWithLabel} Label={{ label: 'I accept the license agreement' }}/>
                  </Box>
                  <Snackbar open={this.state.snackBarStatus} autoHideDuration={10000} onClose={() => {this.setSnackbarStatus(false)}}>
                    <Alert variant="filled" severity={this.state.snackBarSeverity}>{this.state.snackBarMessage}</Alert>
                  </Snackbar>
                </FormikStep>
            </FormikStepper > 
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export interface FormikStepProps extends Pick<FormikConfig<FormikValues>, 'children'>{
  label: string;
}

export function FormikStep({children}: FormikStepProps){
  return <>{children}</>
}

export function FormikStepper({children, ...props}: FormikConfig<FormikValues>){
  const childrenArray = React.Children.toArray(children);
  const[step, setStep] = useState(0);
  const currentChild = childrenArray[step] as React.ElementType<FormikStepProps>;
  const [completed, setCompleted] = useState(false);
  
  function isLastStep(){
    return step === childrenArray.length - 1;
  }

  return (
    <Formik {...props} onSubmit ={async(values, helper) => {
      if(isLastStep()){
        await props.onSubmit(values, helper);
        setCompleted(true);
      }else {
          setStep(s => s+1);
      }
    }}
    > 

      {({isSubmitting}) => (
        
      <Form autoComplete="off">
      <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child: any, index) => (
              <Step key={child.props.label} completed={step > index || completed}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
      {currentChild}

        <Grid container spacing={2}>

          {step > 0 ? (
          <Grid item>
            <Button disabled={isSubmitting} variant="contained" color="primary" onClick= {() => setStep(s=> s-1)}>Back</Button> </Grid> ): null}
          <Grid item>
            <Button startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null} disabled={isSubmitting} variant="contained" color="primary" type="submit">{isSubmitting? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}</Button>
          </Grid>
        </Grid>
      </Form>
      )}
    </Formik>
  );
}