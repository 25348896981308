import { Button, CircularProgress, Fade, Paper, Snackbar, TextField } from '@material-ui/core';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Client, LicenseDto, MachineDto  } from '../Client';
import { Alert } from '@material-ui/lab';
import { Severity } from '../Severity';
import Config from "../Config";

interface IParams {
  }

interface IState {
    activatonCode: string;
  }


interface IProps {
    required: boolean;
    match?: match<IParams>;
    history: any;
  }


export class GetLicense extends React.Component <IProps, IState> {

    constructor(props: any){
        super(props);
    
        this.state = {
          activatonCode: '',
        }
    }

      onActivationCodeInput(event: BaseSyntheticEvent){
        this.setState({
          activatonCode: event.target.value
        });
      }

      route() {
            this.props.history.push('/licenselistview/' + this.state.activatonCode);
        }

      render(){
        return (
          <React.Fragment>
            <Container id="backgroundEkko" maxWidth="xl" className={'cardStyle'}>
              <div style={{textAlign:'center', display:'grid'}}>
                <Typography variant='h4' style={{marginTop: 25}}>Get your License Information</Typography>
                <p>You want to get information about your licenses? Enter your Activation Code! </p>
                <TextField onChange={(event: BaseSyntheticEvent) => this.onActivationCodeInput(event)} label="Activation-Code*" style={{marginBottom: 200}} placeholder="Enter your Activation-Code"></TextField>
                <Button onClick={() => {this.route()}} color="primary" variant="contained" style={{}}>Get your License-Information</Button>
              </div>
            </Container>
          </React.Fragment>
          )
      }
}