import { Button, Card, Container, Typography, Snackbar ,TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { render } from 'react-dom';
import { Client, InfoDto, LicenseDto, LicenseListViewDto, MachineDto  } from '../Client';
import { url } from 'inspector';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import background from "./ekkodale-logo-arrows_transp.png";
import Config from "../Config";
import { Severity } from '../Severity';
import { Alert } from '@material-ui/lab';
const style = require('./Info.css');

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

interface IParams {
  activationCode: string;
}

interface IState {
  licenseListViewDto: LicenseListViewDto | undefined;
  loading: boolean;
  snackBarStatus: boolean;
  snackBarSeverity: "error" | "success" | "info" | "warning" | undefined;
  snackBarMessage: string;
}

interface IProps {
  required: boolean;
  match?: match<IParams>;
  history: any;
}

var client : Client = new Client(Config.BaseUrl);

const lang : string = navigator.language;
const langOptions : Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};


export class LicenseListView extends React.Component<IProps, IState> {

  constructor(props: any){
    super(props);

    this.state = {
      licenseListViewDto: undefined,
      loading: false,
      snackBarStatus: false,
      snackBarSeverity: 'error',
      snackBarMessage: '',
    }
  }

  setLoading(status?: boolean){
    this.setState({
      loading: status !== undefined ? status : !this.state.loading
    })
  }

  setSnackbarStatus(status?: boolean){
    this.setState({
      snackBarStatus: status !== undefined ? status : !this.state.snackBarStatus
    })
  }

  showSnackbar(severity: any, message: string){
    let _severity : "error" | "success" | "info" | "warning" | undefined = severity.toString();
    this.setState({
      snackBarSeverity: _severity,
      snackBarMessage: message
    });
    this.setSnackbarStatus(true);
  }

  async getInformation(){
    try{
        const params  = this.props.match?.params;
        if(params !== undefined){
            let result = await client.licenseListView(params?.activationCode)
            if(result == null){
              this.showSnackbar(Severity.Error, 'Activation Code was not found.');
            }
            this.setState({
              licenseListViewDto: result
            })
        }
    }
    catch(ex){
        if(ex.status == 400){
          this.showSnackbar(Severity.Warning, 'No machines registered.');
        } else {
          this.showSnackbar(Severity.Error, 'Could not connect to ekkodale Server.');
        }
    }
  }

  componentDidMount() {
    this.getInformation();
  }

  render(){
    const params = this.props.match?.params;
    return (
      <React.Fragment>
          <Container id="backgroundEkko" className={'cardStyle'}>
            <div  style={{ backgroundImage: background}}>
                <Typography variant='h3'>Information-View</Typography>
                <h2 className="center">License Information:</h2>
                <p>Your ActivationCode is: <b>{this.props.match?.params.activationCode}</b></p>
            </div>
            <div>
                <h2 className="center">License-Table:</h2>
                    <TableContainer className="tableStyle" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell align="center">Machinename</StyledTableCell>
                                <StyledTableCell align="center">Unique Machine ID</StyledTableCell>
                                <StyledTableCell align="center">Return</StyledTableCell>
                                </TableRow>
                            </TableHead>
                                <TableBody>
                                    {this.state.licenseListViewDto?.listOfMachineDto?.map((row : MachineDto, i) => (
                                    <TableRow key={row.machineIdentification}>
                                        <StyledTableCell align="center" component="th" scope="row">
                                        {row.machineName} 
                                        </StyledTableCell>
                                        <TableCell align="center">{row.machineIdentification}</TableCell>
                                        <InteractiveCell machine={row} license={this.state.licenseListViewDto?.licenseDto} history={this.props.history}/>
                                    </TableRow>
                                    ))}
                                </TableBody>
                        </Table>
                  </TableContainer>
            </div>
            <div>
                  <p>If you have any questions or concerns feel free to contact us or get new Information at:</p>
                  <p><a target="_blank" href="https://www.ekkodale.com/">ekkodale GmbH</a> or via telephone under (+49) 611 34198071.</p>
              </div>
          </Container>
          <Snackbar open={this.state.snackBarStatus} autoHideDuration={10000} onClose={() => {this.setSnackbarStatus(false)}}>
          <Alert variant="filled" severity={this.state.snackBarSeverity}>{this.state.snackBarMessage}</Alert>
        </Snackbar>
        </React.Fragment>
    )
  }
}

export function InteractiveCell(props: any) : JSX.Element{
  if(props.license.selfReturn){
      return <TableCell align="center"><Button onClick={ () => {ReturnLicense(props.license, props.machine)}}>Return</Button></TableCell>
  }
  else{
      return <div/>;
  }
}

export function ReturnLicense(license: any, machine: any){
  try {
      if(machine.machineIdentification !== undefined && license.guid !== undefined){
          client.returnActivation(machine.machineIdentification, license.guid).then((bool: boolean) => {
              if(bool){
                  window.location.reload();
              }
          });
      }
  } catch (error) {
  }
}