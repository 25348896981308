import { Button, Card, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { render } from 'react-dom';
import { Client, InfoDto, LicenseDto, MachineDto  } from '../Client';
import { url } from 'inspector';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import background from "./ekkodale-logo-arrows_transp.png";
import Config from "../Config";
const style = require('./Info.css');


const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

interface IParams {
    machineId: string;
}

interface IState {
    infoDto: InfoDto | undefined;
}

interface IProps {
    required: boolean;
    match?: match<IParams>;
    history: any;
}

var client : Client = new Client(Config.BaseUrl);

const lang : string = navigator.language;
const langOptions : Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

export class Info extends React.Component<IProps, IState> {

    constructor(props: any){
        super(props);

        this.state = {
           infoDto: undefined
        }
      }

    

    async getInformation(){
        try{
            const params  = this.props.match?.params;
            if(params !== undefined){
                let result = await client.getMachineInformation(params?.machineId)
                this.setState({
                    infoDto: result
                })
            }
        }
        catch{
            throw new Error("Invalid Machine-Id");
        }
    }

    componentDidMount() {
       this.getInformation();
    }

  render(){
    const params = this.props.match?.params;
    return (

        
      <React.Fragment>
          <Container id="backgroundEkko" className={'cardStyle'}>
            <div  style={{ backgroundImage: background}}>
                <Typography variant='h3'>Information-View</Typography>
                <h2 className="center">Machine-Information:</h2>
                <p>Your unique MachineID is: <b>{this.state.infoDto?.machineDto?.machineIdentification}</b></p>
            </div>
            <div>
                <h2 className="center">License-Information:</h2>
                    <TableContainer className="tableStyle" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell align="center">Tool-Name</StyledTableCell>
                                <StyledTableCell align="center">StartDate</StyledTableCell>
                                <StyledTableCell align="center">DueDate</StyledTableCell>
                                <StyledTableCell align="center">LicenseType</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                                <TableBody>
                                    {this.state.infoDto?.listOfLicenseDto?.map((row, i) => (
                                    <TableRow key={row.connectedModule}>
                                        <StyledTableCell align="center" component="th" scope="row">
                                        {row.connectedModule}
                                        </StyledTableCell>
                                        <TableCell align="center">{row.startDate?.toLocaleDateString(lang, langOptions)}</TableCell>
                                        <TableCell align="center">{row.dueDateTotal?.toLocaleDateString(lang, langOptions)}</TableCell>
                                        <TableCell align="center">{row.licenseType}</TableCell>
                                        <InteractiveCell license={row} machine={this.state.infoDto?.machineDto} history={this.props.history}/>
                                    </TableRow>
                                    ))}
                                </TableBody>
                        </Table>
                  </TableContainer>
            </div>
            <div>
                  <p>If you have any questions or concerns feel free to contact us or get new Information at:</p>
                  <p><a target="_blank" href="https://www.ekkodale.com/">ekkodale GmbH</a> or via telephone under (+49) 611 34198071.</p>
              </div>
          </Container>
        </React.Fragment>
    )
  }
}

export function InteractiveCell(props: any) : JSX.Element{
    if(props.license.selfReturn){
        return <TableCell align="center"><Button onClick={ () => {ReturnLicense(props.license, props.machine)}}>Return</Button></TableCell>
    }
    else if(props.license.licenseType === "Demo")
    {
        return <TableCell align="center"><Button onClick={ () => {UpgradeLicense(props.license, props.machine, props.history)}}>Upgrade</Button></TableCell>
    }
    else{
        return <div/>;
    }
}

export function ReturnLicense(license: any, machine: any){
    try {
        if(machine.machineIdentification !== undefined && license.guid !== undefined){
            client.returnActivation(machine.machineIdentification, license.guid).then((bool: boolean) => {
                if(bool){
                    window.location.reload();
                }
            });
        }
    } catch (error) {
    }
}

export function UpgradeLicense(license: any, machine: any, history: any){
    try {
        if(machine.machineIdentification !== undefined && license.guid !== undefined){
            history.push('/activation/' + machine.machineIdentification + '/' + machine.operatingSystem + '/' + machine.machineName);
        }
    } catch (error) {
    }
}