import { Button, CircularProgress, Fade, Snackbar, TextField } from '@material-ui/core';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Client, FeedbackDto, LicenseDto, MachineDto  } from '../Client';
import { Alert } from '@material-ui/lab';
import { Severity } from '../Severity';
import Config from "../Config";

interface IParams {
}

interface IState {
  eMail: string;
  feedbackText: string;
  loading: boolean;
  snackBarStatus: boolean;
  snackBarSeverity: "error" | "success" | "info" | "warning" | undefined;
  snackBarMessage: string;
  errorCount: number;
}

interface IProps {
  required: boolean;
  match?: match<IParams>;
  history: any;
}

var client : Client = new Client(Config.BaseUrl);

const sleep = (time: any) => new Promise((acc) => setTimeout(acc, time));

const lang : string = navigator.language;
const langOptions : Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

export class Feedback extends React.Component<IProps, IState> {

  constructor(props: any){
    super(props);

    this.state = {
      eMail: '',
      feedbackText: '',
      loading: false,
      snackBarStatus: false,
      snackBarSeverity: 'error',
      snackBarMessage: '',
      errorCount: 0
    }
  }

  setLoading(status?: boolean){
    this.setState({
      loading: status !== undefined ? status : !this.state.loading
    })
  }

  setSnackbarStatus(status?: boolean){
    this.setState({
      snackBarStatus: status !== undefined ? status : !this.state.snackBarStatus
    })
  }

  showSnackbar(severity: any, message: string){
    let _severity : "error" | "success" | "info" | "warning" | undefined = severity.toString();
    this.setState({
      snackBarSeverity: _severity,
      snackBarMessage: message
    });
    this.setSnackbarStatus(true);
  }


  onFeedbackTextInput(event: BaseSyntheticEvent){
    this.setState({
      feedbackText: event.target.value
    });
  }

  onFeedbackFromInput(event: BaseSyntheticEvent){
    this.setState({
      eMail: event.target.value,
    });
  }

  async feedbacksend(){
    this.setLoading(true);
    let params = this.props.match?.params;

    if(this.state.feedbackText === ""){
      this.showSnackbar(Severity.Warning, 'Field Feedback Input is required.');
      this.setLoading(false);
      return;
    }

    try 
    {
      let result = await client.sendFeedback(new FeedbackDto(
        {
          from: this.state.eMail, 
          text: this.state.feedbackText
        }
        ));
      /*if(result !== null && result !== undefined && result.statusCode == 200){
        this.props.history.push('/feedback/' + result?.licenseType + '/' + result.dueDateTotal?.toLocaleDateString(lang, langOptions));
      }
      else{
        throw new Error("Invalid Activation-Code");
      }*/
    } 
    catch (error) 
    {
      this.setState({
        errorCount: this.state.errorCount + 1
      })
      let errorMessage = "Error: " + error;
      if(error == "TypeError: Failed to fetch"){
        errorMessage = "Could not connect to ekkodale servers. Check your internet connection or try again later.";
      }

      this.showSnackbar(Severity.Error, errorMessage);
      this.setLoading(false);
    }
  }


  render(){
    return (
      <React.Fragment>
        <Container id="backgroundEkko" maxWidth="xl" className={'cardStyle'}>
          <div style={{textAlign:'center', display:'grid'}}>
            <Typography variant='h4' style={{marginTop: 25}}>Send us feedback</Typography>
            <p>Do you have feedback on our tools or licensing mechanism? Feel free to share it with us! You can also send it anonymously!</p>
            <TextField disabled={this.state.loading} onChange={(event: BaseSyntheticEvent) => this.onFeedbackFromInput(event)} label="Optional E-Mail" style={{marginBottom: 20}} placeholder="Consider entering your E-Mail"></TextField>
            <TextField disabled={this.state.loading} onChange={(event: BaseSyntheticEvent) => this.onFeedbackTextInput(event)} multiline label="Feedback-Text*" style={{marginBottom: 20}} placeholder="Please Enter your Feedback"></TextField>
            <Button disabled={this.state.loading} onClick={() => {this.feedbacksend()}} color="primary" variant="contained">Send Feedback</Button>
            <Fade disableStrictModeCompat in={this.state.errorCount > 2} style={{transitionDelay: this.state.loading ? '1000ms' : '0ms'}}>
              <Typography>You have problems sending us feedback? Call Us! <b>+49 611 34198071</b> or visit our Website at <a href="https://www.ekkodale.com/tools" target="_blank">ekkodale.com</a></Typography>
            </Fade>
          </div>
        </Container>
        <Snackbar open={this.state.snackBarStatus} autoHideDuration={10000} onClose={() => {this.setSnackbarStatus(false)}}>
          <Alert variant="filled" severity={this.state.snackBarSeverity}>{this.state.snackBarMessage}</Alert>
        </Snackbar>
      </React.Fragment>
      )
  }
}