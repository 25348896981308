export class ClientBase {
    transformOptions(options: any) {
        //console.log("Options: " + JSON.stringify(options));
        if(options && options.headers){
            options.headers["x-api-key"] = "975806c9-b709-4939-a43c-c5159e4396ae"
        }
    return Promise.resolve(options);
}   
    transformResult(options: any, a: any, b: any) {
        //console.log("Options: " + JSON.stringify(options), a, b);
    return Promise.resolve(options);
    }
}