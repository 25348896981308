import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Config from "./Config";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00D89B"
    },
    secondary: {
      main: "#001A4A"
    }
  }
})

const baseUrl = Config.BaseUrl;

console.log("BaseURL: " + baseUrl);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
