import { Button, CircularProgress, Fade, Paper, Snackbar, TextField } from '@material-ui/core';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Client, LicenseDto, MachineDto  } from '../Client';
import { Alert } from '@material-ui/lab';
import { Severity } from '../Severity';
import Config from "../Config";

interface IParams {
  machineId: string;
  os: string;
  machineName: string;
}

interface IState {
  activatonCode: string;
  loading: boolean;
  snackBarStatus: boolean;
  snackBarSeverity: "error" | "success" | "info" | "warning" | undefined;
  snackBarMessage: string;
  errorCount: number;
}

interface IProps {
  required: boolean;
  match?: match<IParams>;
  history: any;
}

var client : Client = new Client(Config.BaseUrl);

const sleep = (time: any) => new Promise((acc) => setTimeout(acc, time));

const lang : string = navigator.language;
const langOptions : Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

export class Activation extends React.Component<IProps, IState> {

  constructor(props: any){
    super(props);

    this.state = {
      activatonCode: '',
      loading: false,
      snackBarStatus: false,
      snackBarSeverity: 'error',
      snackBarMessage: '',
      errorCount: 0
    }
  }

  setLoading(status?: boolean){
    this.setState({
      loading: status !== undefined ? status : !this.state.loading
    })
  }

  setSnackbarStatus(status?: boolean){
    this.setState({
      snackBarStatus: status !== undefined ? status : !this.state.snackBarStatus
    })
  }

  showSnackbar(severity: any, message: string){
    let _severity : "error" | "success" | "info" | "warning" | undefined = severity.toString();
    this.setState({
      snackBarSeverity: _severity,
      snackBarMessage: message
    });
    this.setSnackbarStatus(true);
  }


  onActivationCodeInput(event: BaseSyntheticEvent){
    this.setState({
      activatonCode: event.target.value
    });
  }

  async activate(){
    this.setLoading(true);
    let params = this.props.match?.params;

    if(this.state.activatonCode === undefined || this.state.activatonCode === ""){
      //console.log("No Activaton Code Input!");
      this.showSnackbar(Severity.Warning, 'Field Activation Code is required.');
      this.setLoading(false);
      return;
    }

    try 
    {
      let result = await client.register(this.state.activatonCode, new MachineDto(
        {
          machineIdentification: params?.machineId, 
          operatingSystem: params?.os,
          machineName: params?.machineName
        }
        ));
      if(result !== null && result !== undefined && result.statusCode == 200){
        this.props.history.push('/success/' + result?.licenseType + '/' + result.dueDateTotal?.toLocaleDateString(lang, langOptions));
      }
      else{
        throw new Error("Invalid Activation Code or maximum number of Activations reached");
      }
    } 
    catch (error) 
    {
      this.setState({
        errorCount: this.state.errorCount + 1
      })
      let errorMessage = error.message;
      if(error == "TypeError: Failed to fetch"){
        errorMessage = "Could not connect to ekkodale servers. Check your internet connection or try again later.";
      }

      //console.error(errorMessage, this.state.errorCount);
      this.showSnackbar(Severity.Error, errorMessage);
      this.setLoading(false);
    }
  }


  render(){
    return (
      <React.Fragment>
        <Container id="backgroundEkko" maxWidth="xl" className={'cardStyle'}>
          <div style={{textAlign:'center', display:'grid'}}>
            <Typography variant='h4' style={{marginTop: 25}}>Activate your License</Typography>
            <p>You have an Activation Code? Simply enter it to get started!</p>
            <TextField disabled={this.state.loading} onChange={(event: BaseSyntheticEvent) => this.onActivationCodeInput(event)} label="Activation-Code*" style={{marginBottom: 200}} placeholder="Enter your Activation Code"></TextField>
            <Button disabled={this.state.loading} onClick={() => {this.activate()}} color="primary" variant="contained" style={{}}>Activate your License</Button>
            <Fade disableStrictModeCompat in={this.state.errorCount > 2} style={{transitionDelay: this.state.loading ? '1000ms' : '0ms'}}>
              <Typography>You have problems activating your license? Call Us! <b>+49 611 34198071</b> or visit our Website at <a href="https://www.ekkodale.com/tools" target="_blank">ekkodale.com</a></Typography>
            </Fade>
            <div style={{alignItems: 'center', marginTop: 10}}>
              <Fade disableStrictModeCompat in={this.state.loading} style={{transitionDelay: this.state.loading ? '200ms' : '0ms'}}>
                <div style={{display: 'inline-flex'}}>
                  <CircularProgress /><p style={{marginLeft: 10}}>Loading...</p>
                </div>
              </Fade>
            </div>
          </div>
        </Container>
        <Snackbar open={this.state.snackBarStatus} autoHideDuration={10000} onClose={() => {this.setSnackbarStatus(false)}}>
          <Alert variant="filled" severity={this.state.snackBarSeverity}>{this.state.snackBarMessage}</Alert>
        </Snackbar>
      </React.Fragment>
      )
  }
}