import { Button, Card, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, match } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
const style = require('./AlreadyRegistered.css');

export class AlreadyRegistered extends React.Component {

  constructor(props: any){
    super(props);
  }

  render(){
    return (
      <React.Fragment>
          <Container id="backgroundEkko" maxWidth="xl" className={'cardStyle'}>
              <div>
                <Typography variant='h4'>Oh no!</Typography>
                <Typography variant='h5'>Your demo expired</Typography>
              </div>
              <div className={'spaceBorder'}>
                <Typography variant='body1'>If you want to continue using the ekkodale tools, you must purchase an official license!</Typography>
              </div>
              <div className={'spaceBorder'}>
                <Typography variant='body1'>If you are interested you can visit www.ekkodale.com/tools to get further information on the tools.</Typography>
                <Typography variant='body1'>Or talk to us under <b>+49 611 34198071</b></Typography>
              </div>
          </Container>
        </React.Fragment>
    )
  }
}