import { Button, Card, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export class EmptyRedirect extends React.Component {

  constructor(props: any){
    super(props);
  }

  render(){
    return (
      (null)
    )
  }
}
